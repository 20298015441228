import React, { useState } from "react";
import about1 from "../images/aboutimage1.png";
import about3 from "../images/aboutimage2.png";
import about2 from "../images/aboutimage3.png";
import CountUp from "react-countup";
import { useLayoutEffect } from "react";
import { Link } from "react-router-dom";

const AboutComp = () => {
  const [countStart, setCountStart] = useState(false);
  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        setCountStart(true);
      }
    });
  });

  useLayoutEffect(() => {
    const countComp = document.querySelector(".countComp");
    observer.observe(countComp);
  }, []);

  return (
    <>
      <div className="relative text-[#262e45bb]">
        <div className="flex items-center p-4 sm:p-10 w-[100%] flex-col-reverse sm:flex-row justify-around sm:sticky top-0 min-h-[90vh]  ">
          <div className="sm:w-[50%] flex items-center">
            <img
              className="w-full max-h-[80%] animate-updown "
              src={about1}
              alt=""
            />
          </div>
          <div className="  sm:w-[40%] flex flex-col  justify-center gap-5">
            <h1 className=" text-3xl sm:text-4xl font-semibold text-center sm:text-left">
              About Company
            </h1>
            <p className="text-justify sm:text-left">
              Welcome to Kirabiz, your trusted partner in navigating the digital
              landscape. We are a dynamic IT consultancy company dedicated to
              delivering innovative solutions tailored to your business needs.
              Our team of experts specializes in technology strategies, software
              development, cybersecurity, and more. With a commitment to
              excellence, we empower businesses to thrive in a rapidly evolving
              tech-driven world. Join us on a journey of growth, efficiency, and
              transformation.
            </p>
            <Link
              to="/about"
              className="bg-[#345fe0dd] text-[#ffffffdd] font-semibold text-lg px-4 py-2 rounded-full mx-auto sm:ml-0 "
            >
              Learn More
            </Link>
          </div>
        </div>
        <div className=" p-4 sm:p-10 w-[100%]  text-[#ffffffdd] sm:sticky top-0 bg-[#345fe0] min-h-[90vh]">
          <div className="items-center flex-col sm:flex-row flex w-full justify-around">
            <div className="sm:w-[40%] flex flex-col justify-center gap-5">
              <h1 className="text-4xl font-semibold text-center sm:text-left">
                Our Mission
              </h1>
              <p className="text-justify sm:text-start">
                Empowering businesses through technology excellence is our
                mission at Kirabiz. We strive to drive innovation, provide
                strategic insights, and deliver exceptional IT solutions that
                propel our clients to new heights of success. With a focus on
                collaboration, integrity, and continuous learning, we're
                dedicated to being the catalyst for your digital transformation
                journey.
              </p>
              <Link
                to="/about"
                className="text-[#345fe0dd] bg-white font-semibold text-lg px-4 py-2  rounded-full mx-auto sm:ml-0 "
              >
                Learn More
              </Link>
            </div>
            <div className="sm:w-[50%] flex items-center ">
              <img className="w-[90%]  animate-updown" src={about2} alt="" />
            </div>
          </div>
          <div className=" bg-[#345fe0] flex justify-center text-center  gap-[10%] p-5 text-[#ffffffdd] font-semibold text-xl countComp">
            <div>
              <h1 className="text-xl md:text-3xl">
                {countStart ? (
                  <>
                    <CountUp start={0} end={1500} duration={5} delay={0} />+
                  </>
                ) : (
                  ""
                )}
              </h1>
              <p className="text-sm sm:text-xl">Satisfied Customer</p>
            </div>
            <div>
              <h1 className="text-xl md:text-3xl">
                {countStart ? (
                  <>
                    <CountUp start={0} end={35} duration={5} delay={0} />+
                  </>
                ) : (
                  ""
                )}
              </h1>
              <p className="text-sm sm:text-xl">Esteem Clients</p>
            </div>
            <div>
              <h1 className="text-xl md:text-3xl">
                {countStart ? (
                  <>
                    <CountUp start={0} end={1000} duration={5} delay={0} />+
                  </>
                ) : (
                  ""
                )}
              </h1>
              <p className="text-sm sm:text-xl">Projects</p>
            </div>
          </div>
        </div>

        <div className="flex flex-col-reverse sm:flex-row p-4 sm:p-10 w-[100%] justify-around sm:sticky top-0 bg-white min-h-[90vh] ">
          <div className="sm:w-[50%] flex items-center ">
            <img
              className="w-full  max-h-[80%] animate-updown"
              src={about3}
              alt=""
            />
          </div>
          <div className="sm:w-[40%] flex flex-col justify-center gap-5">
            <h1 className="text-3xl sm:text-4xl font-semibold text-center sm:text-left">
              Our Vision
            </h1>
            <p className="text-justify sm:text-start">
              At Kirabiz, our vision is to be a trailblazing force in shaping
              the future of technology. We aspire to become a global leader in
              IT consultancy services, recognized for our unwavering commitment
              to driving innovation, fostering meaningful client relationships,
              and making a positive impact on businesses and communities
              worldwide. Through our visionary approach, we aim to redefine
              possibilities and set new benchmarks in the realm of technology
              solutions.
            </p>
            <Link
              to="/about"
              className="bg-[#345fe0dd] text-[#ffffffdd] font-semibold text-lg px-4 py-2 rounded-full mx-auto sm:ml-0"
            >
              Learn More
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutComp;
