import React from "react";
import ServiceCard from "../components/Services/ServiceCard";

const Services = () => {
  const ServiceCardData = [
    {
      title: "Web Development",
      description:
        "Elevate your online presence with our cutting-edge web development services. From responsive designs to seamless user experiences, we create websites that captivate audiences and drive business growth. Our expert developers blend creativity and functionality to deliver websites that leave a lasting impression.",
    },
    {
      title: "App Development",
      description:
        "Transform your ideas into dynamic mobile experiences with our app development prowess. We specialize in crafting user-friendly and feature-rich applications for iOS and Android platforms. Whether it's for business or consumer needs, our apps are built to engage, inspire, and deliver exceptional value.",
    },
    {
      title: "Software Product",
      description:
        "Unlock the potential of your software product with our comprehensive services. From concept to deployment and beyond, we offer end-to-end solutions tailored to your product's unique requirements. With a focus on innovation, quality, and scalability, we ensure your software stands out in today's competitive market.",
    },
    {
      title: "Digital Marketing",
      description:
        "Navigate the digital landscape with our results-driven digital marketing strategies. From SEO and social media to content creation and paid campaigns, we orchestrate comprehensive approaches that boost your brand's visibility, engagement, and conversion rates. Let us optimize your digital footprint for success.",
    },
  ];
  return (
    <>
      <div className="bg-[#345fe0dd] h-16"></div>
      <section className=" py-16 px-4 md:px-16 ">
        <h2 className="text-4xl font-semibold text-center pb-10">
          Our Services
        </h2>
        <div className=" rounded-2xl grid grid-cols-1 sm:grid-cols-2 gap-x-5 gap-y-10 relative">
          {ServiceCardData.map((card) => {
            return (
              <ServiceCard
                title={card.title}
                description={card.description}
                type="s_card"
              />
            );
          })}
        </div>
      </section>
    </>
  );
};

export default Services;
