import React, { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { Link } from "react-router-dom";

const Sidebar = (props) => {
  const removeModalHandler = () => {
    props.setShowModal(false);
  };

  return (
    <div
      className={
        "h-[100vh] w-[100vw] z-30 bg-[#ffffff11] backdrop-blur-sm fixed md:hidden " +
        props.className
      }
    >
      <div className="w-[50%] h-[100%] text-white font-semibold bg-[#345fe0] flex flex-col gap-5 items-center absolute right-0">
        <button className=" p-3 mb-10 self-end" onClick={removeModalHandler}>
          <AiOutlineClose />
        </button>

        <Link to="/home" onClick={removeModalHandler}>
          Home
        </Link>
        <Link to="/programs" onClick={removeModalHandler}>
          Programs
        </Link>
        <Link to="/about" onClick={removeModalHandler}>
          About
        </Link>
        <Link to="/services" onClick={removeModalHandler}>
          Service
        </Link>
        <Link to="/contact" onClick={removeModalHandler}>
          Contact
        </Link>
      </div>
    </div>
  );
};

export default Sidebar;
