import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-cards";
import "./Testimonial.css";
// import required modules
import { Autoplay, EffectCards } from "swiper/modules";

export default function Testimonial() {
  return (
    <Swiper
      effect={"cards"}
      modules={[EffectCards, Autoplay]}
      autoplay={{ delay: 2000, disableOnInteraction: false }}
      className="mySwiper min-h-[70vh_!important] w-[80vw_!important] md:w-[55vw_!important] px-4"
      style={{ width: "55vw" }}
    >
      <SwiperSlide className="rounded-2xl w-[100%] flex flex-col justify-around md:gap-5 py-6 md:py-14">
        <div className="testContainer">
          <p className="bg-[#c9d3fd66] text-[#262e45bb] text-3xl h-16 w-16 flex items-center justify-center rounded-full">
            GM
          </p>
          <p className="text-4xl">Gaurav Mahajan</p>
          <p className="text-2xl">Placed in Oracle</p>
        </div>
        <p className="h-[50%] p-5 sm:px-10 text-center text-base md:text-xl font-normal">
        I had an outstanding experience with Kirabiz. Their comprehensive training and internship program not only equipped me with the technical skills required for a Python developer but also honed my soft skills and improved my resume. Thanks to their support, I successfully secured a placement at Oracle. Kirabiz is truly a game-changer in career development.
        </p>
      </SwiperSlide>
      <SwiperSlide className="rounded-2xl w-[100%] flex flex-col justify-around md:gap-5 py-6 md:py-14">
        <div className="testContainer">
          <p className="bg-[#c9d3fd66] text-[#262e45bb] text-3xl h-16 w-16 flex items-center justify-center rounded-full">
            SB
          </p>
          <p className="text-4xl">Shruti Bagrodia</p>
          <p className="text-2xl">Placed in Deloitte</p>
        </div>
        <p className="h-[50%] p-5 sm:px-10 text-center text-base md:text-xl font-normal">
        I am incredibly grateful to Kirabiz for their exceptional training and internship program. They equipped me with the technical skills I needed as a Full Stack developer, helped me build valuable soft skills, and guided me in crafting a standout resume. Thanks to their support, I secured a placement at Deloitte, and I couldn't be happier with the results. Kirabiz truly delivers on its promises.
        </p>
      </SwiperSlide>
      <SwiperSlide className="rounded-2xl w-[100%] flex flex-col justify-around md:gap-5 py-6 md:py-14">
        <div className="testContainer">
          <p className="bg-[#c9d3fd66] text-[#262e45bb] text-3xl h-16 w-16 flex items-center justify-center rounded-full">
            SG
          </p>
          <p className="text-4xl">Sahil Goel</p>
          <p className="text-2xl">Placed in Bajaj</p>
        </div>
        <p className="h-[50%] p-5 sm:px-10 text-center text-base md:text-xl font-normal">
        I am extremely grateful to Kirabiz for their invaluable assistance in securing a placement at Bajaj. Their comprehensive Data Science training, coupled with a well-structured internship program, not only honed my technical skills but also equipped me with essential soft skills. Their guidance in crafting a stellar resume was instrumental in landing this opportunity. Kirabiz truly stands out as a career development partner.
        </p>
      </SwiperSlide>
      <SwiperSlide className="rounded-2xl w-[100%] flex flex-col justify-around md:gap-5 py-6 md:py-14">
        <div className="flex flex-col justify-center gap-3 items-center h-[50%]">
          <p className="bg-[#c9d3fd66] text-white text-3xl h-16 w-16 flex items-center justify-center rounded-full">
            JG
          </p>
          <p className="text-4xl">Jai Gupta</p>
          <p className="text-2xl">Placed in Deloitte</p>
        </div>
        <p className="h-[50%] p-5 sm:px-10 text-center text-base md:text-xl font-normal">
        Kirabiz has been instrumental in shaping my career. Their training not only honed my technical skills as a Python developer but also enhanced my soft skills, making me a well-rounded professional. The internship opportunity they provided was invaluable, and their guidance in crafting a compelling resume was instrumental in securing a position at Deloitte. I can't thank Kirabiz enough for their unwavering support and commitment to my success.
        </p>
      </SwiperSlide>
    </Swiper>
  );
}
