import { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import bg from "../images/data_science.jpeg";

export const ProgramContext = createContext();

const ProgramStates = (props) => {
  const programData = [
    {
      id: 1,
      name: "Data Science",
      description:
        "Data Science is a multidisciplinary field that combines techniques from statistics, computer science, and domain expertise to extract valuable insights and knowledge from data.",
      bgImage: bg,
      cardContent: [
        "21+ hours of on-demand video",
        "Teaching Assistance",
        "Interview Prep",
        "Soft Skills Training"
      ],
      enrollments: 1300,
      review: 200,
      view: 400,
      free: [
        "You get a self paced course",
        "Teaching assistance if you are stuck anywhere",
        "Soft skills training sessions",
        "Resume building sessions",
      ],
      selfPaced: [" 1 Client Project","Apply the learned tech stack to client project","Mentorship", "Corporate Exposure"],
      selfPacedPlus: ["Performance Based Stipend", "Internship Completion Certificate", "Course Completion Certificate", "Letter of Recommendation"],
    },
    {
      id: 2,
      name: "MERN Stack Web Development",
      description:
        "Full stack web development with the MERN stack refers to the practice of building dynamic and interactive web applications using a specific set of technologies and tools. MERN is an acronym that stands for: MongoDB,Express,React,Node.",
      bgImage: bg,
      cardContent: [
        "21+ hours of on-demand video",
        "Teaching Assistance",
        "Interview Prep",
        "Soft Skills Training"
      ],
      enrollments: 1300,
      review: 1300,
      view: 1300,
      free: [
        "You get a self paced course",
        "Teaching assistance if you are stuck anywhere",
        "Soft skills training sessions",
        "Resume building sessions",
      ],
      selfPaced: [" 1 Client Project","Apply the learned tech stack to client project","Mentorship", "Corporate Exposure"],
      selfPacedPlus: [" Performance Based Stipend", "Internship Completion Certificate", "Course Completion Certificate", "Letter of Recommendation"],
    },
    {
      id: 3,
      name: "AI/ML",
      description:
        "The field of Artificial Intelligence (AI) and Machine Learning (ML) is a rapidly evolving and interdisciplinary domain that focuses on creating systems and algorithms that can mimic human intelligence and learn from data.",
      bgImage: bg,
      cardContent: [
        "21+ hours of on-demand video",
        "Teaching Assistance",
        "Interview Prep",
        "Soft Skills Training"
      ],
      enrollments: 1300,
      review: 1300,
      view: 1300,
      free: [
        "You get a self paced course",
        "Teaching assistance if you are stuck anywhere",
        "Soft skills training sessions",
        "Resume building sessions",
      ],
      selfPaced: [" 1 Client Project","Apply the learned tech stack to client project","Mentorship", "Corporate Exposure"],
      selfPacedPlus: [" Performance Based Stipend", "Internship Completion Certificate", "Course Completion Certificate", "Letter of Recommendation"],
    },
    {
      id: 4,
      name: "App Development",
      description:
        "Flutter and React Native Mobile app development is the process of creating software applications specifically designed to run on mobile devices, such as smartphones and tablets. It has become a critical part of the modern digital landscape, enabling businesses, individuals, and organizations to reach and engage with their target audience on a portable platform.",
      bgImage: bg,
      cardContent: [
        "21+ hours of on-demand video",
        "Teaching Assistance",
        "Interview Prep",
        "Soft Skills Training"
      ],
      enrollments: 1300,
      review: 1300,
      view: 1300,
      free: [
        "You get a self paced course",
        "Teaching assistance if you are stuck anywhere",
        "Soft skills training sessions",
        "Resume building sessions",
      ],
      selfPaced: [" 1 Client Project","Apply the learned tech stack to client project","Mentorship", "Corporate Exposure"],
      selfPacedPlus: [" Performance Based Stipend", "Internship Completion Certificate", "Course Completion Certificate", "Letter of Recommendation"],
    },
    {
      id: 5,
      name: "Python Developer",
      description:
        "A Python developer is a software engineer or programmer who specializes in using the Python programming language to create, maintain, and improve software applications, websites, and other software-related projects. Python is a versatile and widely-used programming language known for its readability, simplicity, and extensive libraries, making it a popular choice for a wide range of development tasks.",
      bgImage: bg,
      cardContent: [
        "21+ hours of on-demand video",
        "Teaching Assistance",
        "Interview Prep",
        "Soft Skills Training"
      ],
      enrollments: 1300,
      review: 1300,
      view: 1300,
      free: [
        "You get a self paced course",
        "Teaching assistance if you are stuck anywhere",
        "Soft skills training sessions",
        "Resume building sessions",
      ],
      selfPaced: [" 1 Client Project","Apply the learned tech stack to client project","Mentorship", "Corporate Exposure"],
      selfPacedPlus: [" Performance Based Stipend", "Internship Completion Certificate", "Course Completion Certificate", "Letter of Recommendation"],
    },
    {
      id: 6,
      name: "UI/UX",
      description:
        "A UI/UX Developer, also known as a User Interface/User Experience Developer, is a professional responsible for creating and enhancing the visual and interactive aspects of digital products and applications. This role combines elements of design and development to ensure that software and websites are not only aesthetically pleasing but also user-friendly and efficient.",
      bgImage: bg,
      cardContent: [
        "21+ hours of on-demand video",
        "Teaching Assistance",
        "Interview Prep",
        "Soft Skills Training"
      ],
      enrollments: 1300,
      review: 1300,
      view: 1300,
      free: [
        "You get a self paced course",
        "Teaching assistance if you are stuck anywhere",
        "Soft skills training sessions",
        "Resume building sessions",
      ],
      selfPaced: [" 1 Client Project","Apply the learned tech stack to client project","Mentorship", "Corporate Exposure"],
      selfPacedPlus: [" Performance Based Stipend", "Internship Completion Certificate", "Course Completion Certificate", "Letter of Recommendation"],
    },

  ];

  return (
    <ProgramContext.Provider value={{ programData }}>
      {props.children}
    </ProgramContext.Provider>
  );
};

export default ProgramStates;
