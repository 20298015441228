import React from "react";
import AboutComp from "../components/AboutComp";

const About = () => {
  return (
    <>
      <div className="bg-[#345fe0dd] h-16"></div>
      <AboutComp />
    </>
  );
};

export default About;
