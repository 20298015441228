import Header from "./components/Header";
import Home from "./pages/Home";
import Footer from "./components/Footer";
import { Routes, Route } from "react-router-dom";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Services from "./pages/Services";
import { useLayoutEffect, useState } from "react";
import ContactModal from "./components/ContactModal";
import { BsWhatsapp } from "react-icons/bs";
import { FiPhone } from "react-icons/fi";
import Programs from "./pages/Programs";
import Program from "./pages/Program";

function App() {
  const [showForm, setShowForm] = useState(false);
  useLayoutEffect(() => {
    setTimeout(() => {
      setShowForm(true);
    }, 5000);
  }, []);
  return (
    <>
      <ContactModal
        className={showForm ? "block" : "hidden"}
        showHandler={setShowForm}
      />
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/programs" element={<Programs />} />
        <Route path="/program/:id" element={<Program />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
      <Footer />
      <a
        href="tel:9111884992"
        target="_blank"
        className="fixed bottom-24 right-5 z-50 bg-blue-400 p-3 rounded-full cursor-pointer transition-all duration-600 hover:scale-125 "
      >
        <FiPhone size={30} color="white" />
      </a>
      <a
        href="https://wa.me/9111884992"
        target="_blank"
        className="fixed bottom-5 right-5 z-50 bg-green-400 p-3 rounded-full cursor-pointer transition-all duration-600 hover:scale-125"
      >
        <BsWhatsapp size={30} color="white" />
      </a>
    </>
  );
}

export default App;
