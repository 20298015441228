import React, { useContext, useLayoutEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { ProgramContext } from "../context/ProgramContext";
import CountUp from "react-countup";
import { AiFillCaretRight } from "react-icons/ai";
import Testimonial from "../components/Home/Testimonial";
import certificate from "../images/certificate.png";
import lor from "../images/lor.png";

const Program = () => {
  const { id } = useParams();
  let { programData } = useContext(ProgramContext);
  programData = programData.filter((program) => program.id === parseInt(id));
  const [countStart, setCountStart] = useState(false);
  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        setCountStart(true);
      }
    });
  });

  useLayoutEffect(() => {
    const countComp = document.querySelector(".countComp");
    observer.observe(countComp);
  }, []);
  return (
    <>
      <div className="bg-[#345fe0dd] h-16"></div>
      <section className=" relative w-[100%] h-[70vh] flex items-center justify-center overflow-hidden">
        <div className=" h-[100%] w-[100%] top-0 left-0 overflow-hidden absolute -z-10">
          <img src={programData[0].bgImage} className="w-full h-full" />
        </div>
        <div className=" h-[100%] w-[100%] top-0 left-0 overflow-hidden absolute -z-10 bg-black/10 backdrop-blur-sm"></div>
        <div className=" flex flex-col items-center justify-center gap-20 z-10 px-4 text-white my-auto ">
          <h1 className="text-4xl sm:text-5xl md:text-6xl text-center font-semibold">
            {programData[0].name}
          </h1>
          <Link
            to={"/contact"}
            className="hover:bg-white hover:text-[#262e45] sm:text-lg font-[600] py-4 px-8 rounded-full text-white bg-[#345fe0] transition-colors duration-400 z-40 uppercase"
          >
            Request a CallBack
          </Link>
        </div>
      </section>
      <section className=" py-16 px-4 md:px-16 overflow-hidden">
        <div className=" rounded-2xl grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-x-5 gap-y-10 relative">
          {programData[0]?.cardContent?.map((card) => (
            <div className="h-48 flex items-center justify-center text-2xl px-5 text-center bg-[#345fe0] text-[#ffffffdd] rounded-xl hover:scale-110 transition-transform duration-500">
              {card}
            </div>
          ))}
        </div>
      </section>
      <section className=" bg-[#345fe0] flex justify-center text-center gap-[10%] px-5 py-20 text-[#ffffffdd] font-semibold text-xl countComp">
        <div>
          <h3 className="text-xl md:text-3xl">
            {countStart ? (
              <>
                <CountUp
                  start={0}
                  end={programData[0]?.enrollments}
                  duration={5}
                  delay={0}
                />
                +
              </>
            ) : (
              ""
            )}
          </h3>
          <p className="text-sm sm:text-xl">Student Enrollments</p>
        </div>
        <div>
          <h3 className="text-xl md:text-3xl">
            {countStart ? (
              <>
                <CountUp
                  start={0}
                  end={programData[0]?.review}
                  duration={5}
                  delay={0}
                />
                +
              </>
            ) : (
              ""
            )}
          </h3>
          <p className="text-sm sm:text-xl">Projects</p>
        </div>
        <div>
          <h3 className="text-xl md:text-3xl">
            {countStart ? (
              <>
                <CountUp
                  start={0}
                  end={programData[0]?.view}
                  duration={5}
                  delay={0}
                />
                +
              </>
            ) : (
              ""
            )}
          </h3>
          <p className="text-sm sm:text-xl">Company Refferals</p>
        </div>
      </section>
      <section className=" py-16 px-4 md:px-16 overflow-hidden">
        <div className=" rounded-2xl grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-16 gap-y-10 relative">
          <div className="flex flex-col gap-10">
            <h3 className="text-center text-4xl font-bold">Training</h3>
            <div className=" flex flex-col gap-4 text-lg p-10  bg-[#345fe0] text-[#ffffffdd] rounded-xl hover:scale-110 transition-transform duration-500">
              {programData[0]?.free?.map((card) => (
                <p className="flex ">
                  <AiFillCaretRight className="w-[10%] mt-1" />
                  <p className="w-[90%]">{card}</p>
                </p>
              ))}
            </div>
          </div>
          <div className="flex flex-col gap-10">
            <h3 className="text-center text-4xl font-bold">Client Project</h3>
            <div className=" flex flex-col gap-4 text-lg p-10  bg-[#345fe0] text-[#ffffffdd] rounded-xl hover:scale-110 transition-transform duration-500">
              {programData[0]?.selfPaced?.map((card) => (
                <p className="flex ">
                  <AiFillCaretRight className="w-[10%] mt-1" />
                  <p className="w-[90%]">{card}</p>
                </p>
              ))}
            </div>
          </div>
          <div className="flex flex-col gap-10">
            <h3 className="text-center text-4xl font-bold">Benefits</h3>
            <div className=" flex flex-col gap-4 text-lg p-10 bg-[#345fe0] text-[#ffffffdd] rounded-xl hover:scale-110 transition-transform duration-500">
              {programData[0]?.selfPacedPlus?.map((card) => (
                <p className="flex ">
                  <AiFillCaretRight className="w-[10%] mt-1" />
                  <p className="w-[90%]">{card}</p>
                </p>
              ))}
            </div>
          </div>
        </div>
      </section>
      <section className="bg-[#345fe0] text-center px-5 py-16 text-[#ffffffdd]">
        <h3 className="text-center text-4xl font-bold w-full">
          Industry Recognized Certifications
        </h3>
        <div className=" flex justify-center gap-10  font-semibold text-xl py-10 max-sm:flex-col max-sm:items-center">
          <img
            src={certificate}
            className="w-[35%] max-xs:w-full max-sm:w-[70%]"
          />
          <img
            src={lor}
            className="w-[35%] max-xs:w-full max-sm:w-[70%]"
          />
        </div>
      </section>
      <section className="pt-10 overflow-hidden">
        <h3 className="text-center text-4xl font-bold w-full py-10">
          Hear From Our Students
        </h3>
        <Testimonial />
      </section>
    </>
  );
};

export default Program;
